import React from "react";

import { useTranslation } from "react-i18next";

import {
  Segment,
  Grid,
  Container,
  Divider,
  Header,
  Icon,
} from "semantic-ui-react";
export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <Segment
      inverted
      vertical
      style={{ margin: "5em 0em 0em", padding: "5em 0em" }}
    >
      <Container textAlign="center">
        <Grid columns={3} divided inverted stackable>
          <Grid.Column>
            <Icon name="location arrow" size="huge" color="red" />
            <Header
              inverted
              as="h4"
              content={t("footer.capitalized.address")}
            />
            <p>{t("footer.address")}</p>
          </Grid.Column>
          <Grid.Column>
            <Icon name="mail" size="huge" color="red" />
            <Header inverted as="h4" content={t("footer.capitalized.email")} />
            <p>{t("main.email")}</p>
          </Grid.Column>
          <Grid.Column>
            <Icon name="phone" flipped="horizontally" size="huge" color="red" />
            <Header inverted as="h4" content={t("footer.capitalized.phone")} />
            <p>{t("main.phone")}</p>
          </Grid.Column>
        </Grid>
        <Divider inverted section />
        {/* <List horizontal inverted divided link size="small">
          <List.Item as="a" href="#">
            {t("footer.sitemap")}
          </List.Item>
          <List.Item as={Link} to={"/" + props.lang + "/press"} href="#">
            {t("footer.impressum")}
          </List.Item>
          <List.Item as="a" href="#">
            {t("footer.privacy")}
          </List.Item>
          <List.Item as={Link} to={"/" + props.lang + "/contactus"} href="#">
            {t("footer.contactus")}
          </List.Item>
        </List> */}
        <p>{t("footer.copyright")}</p>
      </Container>
    </Segment>
  );
}
