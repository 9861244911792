import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { langReducer } from "./lang/reducer";
import { loginReducer } from "./login/reducer";

const reducer = combineReducers({
  lang: langReducer,
  login: loginReducer,
});

const logger = (store) => (next) => (action) => {
  next(action);
};

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, logger));

export const store = createStore(reducer, enhancer);
