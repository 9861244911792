import React from "react";
import { Segment, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function TableGenerator() {
  const { t } = useTranslation("usefulLinks");
  var tableGenerator = t("usefulLinksTable", { returnObjects: true });

  return (
    <Segment raised>
      <Table celled fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("table.components")}</Table.HeaderCell>
            <Table.HeaderCell>{t("table.warranty")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tableGenerator.map((item, key) => (
            <Table.Row key={key}>
              <Table.Cell>{item.components}</Table.Cell>
              <Table.Cell>{item.warranty}</Table.Cell>
            </Table.Row>
          ))}
          <Table.Row key="key12">
            <Table.Cell>Scannelt Meghívó</Table.Cell>
            <Table.Cell>
              {" "}
              <a
                href={require("../../pdf/usefullinks/meghivo_hajniesattila.pdf")}
                rel="noopener noreferrer"
                target="_blank"
              >
                PDF
              </a>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
}
