import React from "react";
import { Segment, Divider, Container, Header } from "semantic-ui-react";
//import { useTranslation } from "react-i18next";
import LocationsMobile from "./LocationsMobile";
import LocationsNormal from "./LocationsNormal";

export default function Locations() {
  return (
    <Segment size="large">
      <Divider hidden />
      <Container textAlign="center">
        <Header as="h2" color="teal">
          Helyszínek
        </Header>
      </Container>
      <Divider hidden />
      <LocationsMobile />
      <LocationsNormal />
    </Segment>
  );
}
