import React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import axios from "axios";
//import { Component } from "react";
import { setLogin } from "../../store/login/actions";
import { getLogin } from "../../store/login/selector";

//import { getLang } from "../../store/lang/selector";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Pictures() {
  let history = useHistory();
  //const lang = useSelector(getLang);
  const login = useSelector(getLogin);
  const [driveUrl, setDriveUrl] = React.useState(null);
  const dispatch = useDispatch();

  axios({
    method: "POST",
    url: "https://api.hajniesattila.hu/api/handler",
    data: { password: login, command: "getPictureURL" },
  }).then((response) => {
    if (response.data.status === "success") {
      setDriveUrl(response.data.message);
    } else if (response.data.status === "fail") {
      dispatch(setLogin(null));
      history.push("/");
    }
  });

  return (
    <Grid
      textAlign="center"
      //style={{ height: "10vh" }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment>
          <Header as="h2" color="teal" textAlign="center">
            OneDrive Link - Képek
          </Header>
          <Segment
            content={
              <a href={driveUrl} rel="noopener noreferrer" target="_blank">
                OneDrive Megosztás
              </a>
            }
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
