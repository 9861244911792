import React from "react";
import { Segment, Container, Header, Divider } from "semantic-ui-react";

//import { useTranslation } from "react-i18next";
import TableGenerator from "./TableGenerator";
export default function UsefulLinks() {
  //const { t } = useTranslation("products");
  return (
    <Segment size="large">
      <Divider hidden />
      <Container textAlign="center">
        <Header as="h2" color="teal">
          Egyéb Információ / Hasznos Linkek
        </Header>
      </Container>
      <Divider hidden />
      <TableGenerator />
    </Segment>
  );
}
