import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import HttpBackend from "i18next-http-backend";
//mport LanguageDetector from "i18next-browser-languagedetector";
import resources from "./locales/resources";

i18n
  //.use(HttpBackend)
  .use(initReactI18next)

  .init({
    resources,

    fallbackLng: "hu",
    //debug: true,
    lng: "hu",
    ns: ["common"],
    defaultNS: "common",

    keySeparator: false, // we use content as keys
    transSupportBasicHtmlNodes: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
      // formatSeparator: ",",
    },
    //transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    // react: {
    //   wait: true,
    // },
    preload: true,
  });

export default i18n;
