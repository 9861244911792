import React from "react";
import HeaderMenu from "./components/Common/HeaderMenu";
import Header from "./components/Common/Header";
import { useSelector } from "react-redux";
import Home from "./components/Home";

import Login from "./components/Login";
import Locations from "./components/Locations";
import { Container } from "semantic-ui-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/Common/Footer";
import { getLang } from "./store/lang/selector";
import { getLogin } from "./store/login/selector";
import UsefulLinks from "./components/UsefulLinks";
import Pictures from "./components/Pictures";
//import { setLogin } from "./store/login/actions";

function App() {
  //const dispatch = useDispatch();
  const lang = useSelector(getLang);
  const password = useSelector(getLogin);
  return (
    <div className="App">
      <BrowserRouter>
        <p></p>
        <Container>
          <Header />
        </Container>

        <HeaderMenu />
        <Container>
          <Switch>
            {password === null ? (
              <>
                <Route exact path="/:l1/:l2/:l3/:l4" component={Login} />
                <Route exact path="/:l1/:l2/:l3/:l4" component={Login} />
                <Route path="/" component={Login} />
              </>
            ) : (
              <>
                <Route exact path="/:lang/" component={Home} />
                <Route path="/:lang/home" component={Home} />
                <Route path="/:lang/locations">
                  <Locations />
                </Route>
                <Route path="/:lang/other">
                  <UsefulLinks />
                </Route>
                <Route path="/:lang/pictures" component={Pictures} />
                <Route path="/:lang/logoff">
                  <Login action="logoff" />
                </Route>
              </>
            )}
          </Switch>
        </Container>
        <Footer lang={lang} />
      </BrowserRouter>
    </div>
  );
}

export default App;
