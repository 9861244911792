import React from "react";
import {
  Grid,
  Container,
  Header,
  List,
  Dropdown,
  Divider,
} from "semantic-ui-react";
//import Flag from "react-world-flags";
//import { Link } from "react-router-dom";
import { getLang } from "../../store/lang/selector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function HeaderNormal(props) {
  const { t } = useTranslation();
  const lang = useSelector(getLang);
  return (
    <Grid>
      <Grid.Row className="mobile hidden">
        <Grid.Column width={7} floated="left">
          <Container color="#8797A8" textAlign="left">
            <Divider hidden />
            <Header
              style={{
                fontFamily: "Great Vibes, cursive",
                fontSize: "3em",
                color: "white",
              }}
            >
              Hajni & Attila
            </Header>
          </Container>
        </Grid.Column>

        <Grid.Column width={9} floated="right">
          <List floated="right">
            <List.Item>
              <List selection floated="right" horizontal size="mini">
                <List.Item>
                  <Dropdown
                    button
                    className="icon"
                    floating
                    labeled
                    icon="world"
                    options={props.languageOptions}
                    search
                    value={lang}
                    onChange={props.changeLanguage}
                  />
                </List.Item>
              </List>
            </List.Item>
            <List.Item>
              <List floated="right" relaxed>
                <List.Item>
                  <List.Icon name="phone" size="large" inverted color="grey" />
                  <List.Content style={{ color: "white" }}>
                    {t("main.phone")}
                  </List.Content>
                </List.Item>
              </List>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
