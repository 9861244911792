import { SET_LANG } from "./actions";

export function langReducer(lang = "", action) {
  if (action.type === SET_LANG) {
    lang = action.lang;
  } else {
    lang = "hu";
  }
  return lang;
}
