import React from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  List,
  Dropdown,
  Segment,
} from "semantic-ui-react";
import { getLang } from "../../store/lang/selector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function HeaderMobile(props) {
  const { t } = useTranslation();
  const lang = useSelector(getLang);
  return (
    <Grid>
      <Grid.Row only="mobile">
        <Grid.Column width={7} floated="left">
          <Container color="#8797A8" textAlign="left">
            <Divider hidden />
            <Header
              style={{
                fontFamily: "Great Vibes, cursive",
                fontSize: "3em",
                color: "white",
              }}
            >
              Hajni & Attila
            </Header>
          </Container>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row only="mobile">
        <Grid.Column>
          <List>
            <List.Item>
              <Segment size="mini" basic style={{ paddingLeft: "0.5em" }}>
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon="world"
                  options={props.languageOptions}
                  search
                  defaultValue={lang}
                  onChange={props.changeLanguage}
                />
              </Segment>
            </List.Item>
            <List.Item>
              <List relaxed>
                <List.Item>
                  <List.Icon name="phone" size="large" inverted color="grey" />
                  <List.Content>{t("main.phone")}</List.Content>
                </List.Item>
              </List>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
