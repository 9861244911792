import React from "react";
import {
  Segment,
  Divider,
  Label,
  Grid,
  Image,
  Container,
  Header,
  Button,
} from "semantic-ui-react";
//import { useTranslation } from "react-i18next";

// import Paragraphs from "./Paragraphs";
// import TableGenerator from "./TableGenerator";

export default function LocationsMobile() {
  // const { t } = useTranslation("garage");
  // const parag1 = t("parag1", { returnObjects: true });
  // const parag2 = t("parag2", { returnObjects: true });
  // const parag3 = t("parag3", { returnObjects: true });
  return (
    <Grid centered>
      <Grid.Column only="mobile" width={16}>
        <Segment raised>
          <Label ribbon>
            <b>1. Dabasi Református Templom</b>
          </Label>
          <Image fluid src={require("../../img/locations/templom.JPG")} />
        </Segment>
      </Grid.Column>
      <Grid.Column only="mobile" width={16}>
        <Segment raised>
          <Label ribbon>
            <b>Templomi Szertartás</b>
          </Label>
          <Container textAlign="center">
            <Header as="h4">Templomi szertartás</Header>
            <p>Helyszín: Dabasi Református Templom</p>
            <p>Dabas, Bajcsy-Zsilinszky u. 8, 2370</p>
            <p>Gyülekező: 15:30 (be lehet ülni)</p>
            <p>Szertartás: 16:00 (minden vendég ülve) </p>
            <p>
              Parkolás: Az utcán vagy a templom mellett kialakított parkolóban
            </p>
            <Divider hidden />
            <Button
              as={"a"}
              //href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
              href="https://goo.gl/maps/aBxPUrZXbiXPqvDR6 "
              target="_blank"
            >
              Google Maps Link
            </Button>{" "}
            <Button
              target="_blank"
              as={"a"}
              //href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
              href="https://ul.waze.com/ul?place=ChIJreqz-vaOQUcROT1AKwHgm_E&ll=47.18609190%2C19.31681590&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location "
            >
              Waze Link
            </Button>
          </Container>
          <Divider hidden />
          <iframe
            title="Maps embeded"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2711.5578997246253!2d19.314627215615957!3d47.18609187915903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47418ef6fab3eaad%3A0xf19be0012b403d39!2sDabas%2C%20Bajcsy-Zsilinszky%20u.%208%2C%202370!5e0!3m2!1shu!2shu!4v1628204148426!5m2!1shu!2shu"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </Segment>
      </Grid.Column>
      <Grid.Column only="mobile" width={16}>
        <Segment raised>
          <Label ribbon>
            <b>2. Halász Móricz Kúria</b>
          </Label>
          <Image fluid src={require("../../img/locations/halaszmoricz.jpg")} />
        </Segment>
      </Grid.Column>
      <Grid.Column only="mobile" width={16}>
        <Segment raised>
          <Label ribbon>
            <b>Fotózás</b>
          </Label>
          <Container textAlign="center">
            <Header as="h4">Fotózás</Header>
            <p>Helyszín: Halász-Móricz Kúria </p>
            <p>Dabas, Kossuth Lajos út 7, 2373</p>
            <p>Parkolás: A létesítmény és a templom közötti utcában</p>
            <Divider hidden />
            <Button
              as={"a"}
              //href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
              href="https://goo.gl/maps/wzdenZMz2HUcYQVw7"
              target="_blank"
            >
              Google Maps Link
            </Button>{" "}
            <Button
              as={"a"}
              //href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
              href="https://www.waze.com/live-map/directions/hungary/dabas/kossuth-lajos-ut-7?to=place.ChIJWywlbOGOQUcRc6nfENiQgxw&from=ll.47.18635119%2C19.31540251&utm_medium=lm_share_directions&utm_campaign=default&utm_source=waze_website"
              target="_blank"
            >
              Waze Link
            </Button>
          </Container>
          <Divider hidden />
          <iframe
            title="Maps embeded 2"
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10847.62116653104!2d19.31240054338028!3d47.17929085149935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x47418ef6fab3eaad%3A0xf19be0012b403d39!2sDabas%2C%20Bajcsy-Zsilinszky%20u.%208%2C%202370!3m2!1d47.1860919!2d19.316815899999998!4m5!1s0x47418ee16c252c5b%3A0x1c8390d810dfa973!2sDabas%2C%20Kossuth%20Lajos%20%C3%BAt%207%2C%202373!3m2!1d47.172276499999995!2d19.3214778!5e0!3m2!1shu!2shu!4v1628204647478!5m2!1shu!2shu"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
          <p>
            A beágyazott és a linken elérhető térkép csak tájékoztató jellegű, a
            tényleges útvonal ettől eltérhet.
          </p>
        </Segment>
      </Grid.Column>
      <Grid.Column only="mobile" width={16}>
        <Segment raised>
          <Label ribbon>
            <b>3. Viktória Fogadó</b>
          </Label>
          <Image fluid src={require("../../img/locations/viktoria.jpg")} />
          <Divider hidden />
          <Image fluid src={require("../../img/locations/viktoria2.jpg")} />
        </Segment>
      </Grid.Column>
      <Grid.Column only="mobile" width={16}>
        <Divider hidden />
        <Segment raised>
          <Label ribbon>
            <b>Polgári Szertartás és Lakodalom</b>
          </Label>
          <Container textAlign="center">
            <Header as="h4">Polgári Szertartás és Lakodalom</Header>
            <p>Helyszín: Viktória fogadó</p>
            <p>Inárcs, Galamb u. 2, 2365</p>
            <p>Várható Érkezés: 18:00</p>
            <p>
              Parkolás: A fogadóhoz érve a vendégparkoló oldalról közelíthető
              meg egy hosszabb szervízúton a telek mentén
            </p>
            <p>
              Fontos: Inárcsra érve ne a GPS alapján közlekedjük hanem
              használjuk a kihelyezett "Viktória Fogadó" táblákat, mert nem
              minden út járható a fogadó felé
            </p>
            <Divider hidden />
            <Button
              as={"a"}
              //href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
              href="https://goo.gl/maps/YJj84eTVYBrMraPj9"
              target="_blank"
            >
              Google Maps Link
            </Button>{" "}
            {/* <Button
                as={"a"}
                //href={"/" + this.props.lang + "/products#generalTechnicalDetals"}
                href="https://www.waze.com/live-map/directions/hungary/dabas/kossuth-lajos-ut-7?to=place.ChIJWywlbOGOQUcRc6nfENiQgxw&from=ll.47.18635119%2C19.31540251&utm_medium=lm_share_directions&utm_campaign=default&utm_source=waze_website"
                target="_blank"
              >
                Waze Link
              </Button> */}
          </Container>
          <Divider hidden />
          <iframe
            title="Maps embeded 3"
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d29361.936678060836!2d19.291014931140644!3d47.213550169942536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x47418ee16c252c5b%3A0x1c8390d810dfa973!2sKossuth%20Lajos%20%C3%BAt%207%2C%20Dabas%2C%202373!3m2!1d47.172276499999995!2d19.3214778!4m5!1s0x47419184063ba5d5%3A0xcddd33dbdd889cac!2sIn%C3%A1rcs%2C%20Galamb%20u.%202%2C%202365!3m2!1d47.261251099999996!2d19.338126!5e0!3m2!1shu!2shu!4v1628206273579!5m2!1shu!2shu"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
          <p>
            A beágyazott és a linken elérhető térkép csak tájékoztató jellegű, a
            tényleges útvonal ettől eltérhet.
          </p>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
