import TranslationEN from "./en";
import TranslationHU from "./hu";


let resources = {
  en: TranslationEN,
  hu: TranslationHU,

};

export default resources;
