import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Dropdown, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { setLang } from "../../store/lang/actions";
import { getLogin } from "../../store/login/selector";

import i18n from "i18next";

const changeLanguage = (lng) => {
  const clng = i18n.language;
  if (clng !== lng) {
    i18n.changeLanguage(lng);
  }
};

const languageHandler = (lang) => {
  //const allowedLanguages = ["en", "hu"];
  const allowedLanguages = ["hu"];
  if (
    allowedLanguages.filter((language) =>
      language.includes(lang.toString().toLowerCase())
    ).length === 1
  )
    return lang;
  else return "hu";
};

export default function HeaderMenu() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const password = useSelector(getLogin);
  let url = useRouteMatch("/:lang/:menuItem");
  let urlLangOnly = useRouteMatch("/:lang");
  let menuItem = "home";
  let lang = "hu";

  if (url !== null) {
    lang = url.params.lang;
    menuItem = url.params.menuItem;
  }

  if (urlLangOnly !== null && url === null) {
    lang = urlLangOnly.params.lang;
  }
  lang = languageHandler(lang);

  dispatch(setLang(lang));
  changeLanguage(lang);
  //////////////////////////////////

  /////////////////////////////  MENU
  const menuItems = [
    { type: "link", name: "home", content: t("menu.home") },
    { type: "link", name: "locations", content: t("menu.locations") },

    {
      type: "link",
      name: "pictures",
      content: "Képek",
    },
    {
      type: "link",
      name: "other",
      content: t("menu.other"),
    },
    {
      type: "link",
      name: "logoff",
      content: "Kijelentkezés",
    },
  ];

  /////////////////////////////  MENU
  return (
    <Menu size="large" stackable>
      <Container>
        {password !== null
          ? menuItems.map((item, k) =>
              item.type === "link" ? (
                <Menu.Item
                  key={k}
                  name={item.name}
                  active={menuItem === item.name}
                  content={item.content}
                  to={"/" + lang + "/" + item.name}
                  as={Link}
                />
              ) : (
                <Dropdown key={k} item text={item.content}>
                  <Dropdown.Menu>
                    {item.contentList.map((contentItem, k) => (
                      <Dropdown.Item
                        key={k}
                        name={contentItem.name}
                        as={Link}
                        to={
                          "/" + lang + "/" + item.name + "/" + contentItem.name
                        }
                        content={contentItem.content}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )
            )
          : ""}
      </Container>
    </Menu>
  );
}
