import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import Future from "./Future";

export default class Home extends Component {
  render() {
    return (
      <>
        <Image src={require("../../img/home/Hajni_es_Attila-1219_2000.jpg")} />{" "}
        <Future lang={this.props.match.params.lang} />
      </>
    );
  }
}
