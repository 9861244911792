import React from "react";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import axios from "axios";
//import { Component } from "react";
import { setLogin } from "../../store/login/actions";
import { getLogin } from "../../store/login/selector";

import { getLang } from "../../store/lang/selector";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Login(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector(getLang);
  let login = useSelector(getLogin);
  if (props.action === "logoff") {
    dispatch(setLogin(null));
    history.push("/");
  }
  /// props for autologin typeof variable !== 'undefined'
  if (typeof props.match !== "undefined") {
    if (
      typeof props.match.params.l1 !== "undefined" &&
      typeof props.match.params.l2 !== "undefined" &&
      typeof props.match.params.l3 !== "undefined" &&
      typeof props.match.params.l4 !== "undefined"
    ) {
      dispatch(
        setLogin(
          props.match.params.l1 +
            props.match.params.l2 +
            props.match.params.l3 +
            props.match.params.l4
        )
      );
      history.push("/" + lang);
    }
  }
  var state = { password: login, command: "login" };

  const handleChange = (e, { value }) => {
    if (value !== "") {
      state.password = value;
    } else {
      state.password = value;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var error = false;
    if (state.password === "") {
      error = true;
    }
    if (error === false) {
      const newState = { ...state, isLoading: true, sendingError: false };

      axios({
        method: "POST",
        url: "https://api.hajniesattila.hu/api/handler",
        data: newState,
      }).then((response) => {
        if (response.data.status === "success") {
          const newestState = {
            ...state,
            isLoading: false,
            sendingError: false,
          };
          // SET login
          //console.log(state);
          dispatch(setLogin(newestState.password));
          // Set state
          state = { ...newestState };
          history.push("/" + lang);
        } else if (response.data.status === "fail") {
          const newestState = {
            ...state,
            isLoading: false,
            sendingError: true,
          };
          state = { ...newestState };
          history.push("/");
        }
      });
    }
  };

  return (
    <Grid
      textAlign="center"
      //style={{ height: "10vh" }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment>
          <Header as="h2" color="teal" textAlign="center">
            Védett Tartalom
          </Header>
          <Form
            size="large"
            loading={state.isLoading}
            success={state.sendingOk}
            onSubmit={handleSubmit}
          >
            <Segment stacked>
              <Form.Input
                fluid
                icon="lock"
                error={state.sendingError}
                iconPosition="left"
                placeholder="Jelszó a meghívón vagy QR kód"
                type="password"
                onChange={handleChange}
                value={state.password}
              />

              <Button color="teal" fluid size="large">
                Bejelentkezés
              </Button>
            </Segment>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
//export default Login;
