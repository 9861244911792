import React, { Component } from "react";
import {
  Container,
  Segment,
  //Header,
  Divider,
  //  Button,
  //  Message,
  //  Image,
  //Label,
} from "semantic-ui-react";

import { withTranslation } from "react-i18next";
//import VideoGrid from "./VideoGrid";
//import WinnerTeamNormal from "./WinnerTeamNormal";
//import WinnerTeamMobile from "./WinnerTeamMobile";

class FutureClass extends Component {
  render() {
    const { t } = this.props;
    return (
      <Segment size="large">
        <Divider hidden />
        <Container textAlign="center">
          <p
            style={{
              fontFamily: "Great Vibes, cursive",
              fontSize: "3em",
            }}
          >
            Köszönjük, hogy velünk ünnepeltél!
          </p>
        </Container>
        <Divider hidden />

        <Container textAlign="center" raised>
          {/* about us */}

          <Divider hidden />
          {t("paragraph1", { returnObjects: true }).map((item) => (
            <p
              style={{
                fontFamily: "Great Vibes, cursive",
                fontSize: "1.8em",
              }}
            >
              {item}
            </p>
          ))}
          <Divider hidden />
        </Container>
      </Segment>
    );
  }
}
const Future = withTranslation("home")(FutureClass);

export default Future;
