import commonJSON from "./common.json";
import contactusJSON from "./contactus.json";
import garageJSON from "./garage.json";
import usefulLinksJSON from "./usefulLinks.json";
import priceListJSON from "./pricelist.json";
import homeJSON from "./home.json";
import productsJSON from "./products.json";
import productsDetailsJSON from "./products.details.json";

let Translation = {
  common: commonJSON,
  contactus: contactusJSON,
  garage: garageJSON,
  usefulLinks: usefulLinksJSON,
  pricelist: priceListJSON,
  home: homeJSON,
  products: productsJSON,
  productsDetails: productsDetailsJSON,
};

export default Translation;
